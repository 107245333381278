<template>
   <AdviceModal @closeModal="doNotSave">
      <div class="box">
         <AdviceText :bigger="true" class="title">{{ $t('Advice.confirmChangesText') }}</AdviceText>
         <div class="btns">
            <AdviceBtn @click.native="doNotSave">
               <AdviceText :smaller="true">{{ $t('Advice.btnCancel') }}</AdviceText>
            </AdviceBtn>
            <AdviceBtn @click.native="save">
               <AdviceText :smaller="true"> {{ $t('Advice.btnSave') }} </AdviceText>
            </AdviceBtn>
         </div>
      </div>
   </AdviceModal>
</template>

<script>
import AdviceBtn from '../AdviceComponents/AdviceBtn.vue';
import AdviceText from '../AdviceComponents/AdviceText.vue';
import AdviceModal from '../AdviceComponents/AdviceModal.vue';
import mutations from '../../store/mutation-types';
export default {
   components: {
      AdviceModal,
      AdviceText,
      AdviceBtn,
   },
   methods: {
      save() {
         this.closeModal();
      },

      doNotSave() {
         this.$store.dispatch('advice/doNotSaveAdvices');
         this.closeModal();
      },

      closeModal() {
         this.$store.commit(`advice/${mutations.SET_IS_CONFIRM_ADVICES}`, false);
         if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
               type: 'clearCategories',
            });
         }
      },
   },
};
</script>

<style lang="sass" scoped>
.box
   max-width: 380px
   width: 100%
   margin: 0 auto

.title
   text-align: center

.btns
   margin-top: 40px
   display: flex
   justify-content: space-between
</style>