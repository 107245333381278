<template>
   <div @click="$emit('close')" class="add-screen">
      <div class="add-screen__modalscreen">
         <div class="add-screen__header">
            <!-- <h1>Installing on {{ ios ? 'iOS' : 'Android' }}</h1> -->
            <button @click="$emit('close')" class="add-screen__cross">
               <!-- <svg><use xlink:href="../../assets/img/icons.svg#cross-icon"></use></svg> -->
               <!-- <img src="../../assets/img/advices/installScreen.png" alt="" /> -->
            </button>
         </div>
         <div class="add-screen__container">
            <Typography class="add-screen__text" v-if="!ios">
               To install the app from Firefox on Android, tap the ellipsis icon:
            </Typography>
            <Typography class="add-screen__text" v-else>
               1. To install the app from Safari on iOS, <br />
               tap the share button
            </Typography>
            <div class="add-screen__img">
               <img src="../../assets/img/advices/firefox-1.svg" alt="First instruction slide" v-if="!ios" />
               <img src="../../assets/img/advices/iosInstallScreen.svg" alt="First instruction slide" v-else />
            </div>
            <Typography class="add-screen__text text_two">
               2. Then swipe up to find tap {{ ios ? '"Add to Home Screen"' : '"Install"' }}:
            </Typography>
            <div class="add-screen__img">
               <img src="../../assets/img/advices/firefox-2.svg" alt="Second instruction slide" v-if="!ios" />
               <img src="../../assets/img/advices/iosInstall.svg" alt="First instruction slide" v-else />
            </div>
         </div>
         <div class="add-screen__btn-box">
            <SWButton :size="'big'" variant="primary" @click="$emit('close')">Got It</SWButton>
         </div>
      </div>
   </div>
</template>

<script>
import { SWButton } from 'sw-ui-kit';

import Typography from '../UI/Typography.vue';
export default {
   components: {
      SWButton,
      Typography,
   },
   props: {
      ios: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style scoped>
.add-screen {
   position: fixed;
   z-index: 100000;
   height: 100%;
   width: 100%;
   top: 0;
   left: 0;
   background-color: var(--instructionIstall);
   overflow-y: auto;
}
.add-screen__header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-right: 19px;
}
.add-screen__cross {
   width: 25px;
   height: 25px;
}
.add-screen__cross svg {
   width: inherit;
   height: inherit;
}
.add-screen__header h1,
.add-screen__btn-box {
   padding: 8px 19px 50px 19px;
   font-family: Roboto;
   font-style: normal;
   font-weight: bold;
   font-size: 16px;
}
.add-screen__container {
   padding: 15px 19px 10px 19px;
   border-radius: 10px;
}
.add-screen__container > * {
   margin-bottom: 15px;
}
.add-screen__text {
   font-family: Roboto;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   text-align: center;
   color: #000000;
}
.add-screen .text_two {
   max-width: 200px;
   margin: 0 auto;
}
.add-screen__img img {
   width: 100%;
}
.add-screen__modalscreen {
   background-color: var(--surface);
   margin: 5px 16px;
   transform: translate(0, 15%);
   border-radius: 10px;
}

@media screen and (max-width: 375px) {
   .add-screen__modalscreen {
      transform: none;
   }
}
</style>
