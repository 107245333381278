<template>
   <p :style="{ ...font }">
      <slot></slot>
   </p>
</template>
<script>
export default {
   props: {
      smaller: {
         type: Boolean,
         default: false,
      },
      bigger: {
         type: Boolean,
         default: false,
      },

      biggerOn: {
         type: Number,
      },

      smallerOn: {
         type: Number,
      },

      disable: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      currentSize() {
         return this.$store.getters['advice/getCurrentFontSize'];
      },

      font() {
         if (this.disable) return;
         let currentSize = Number(this.currentSize);
         let size;
         let fontSize = {};
         if (!fontSize) return;
         if (this.smaller) size = currentSize - 2 + 'px';
         else if (this.bigger) size = currentSize + 2 + 'px';
         else if (this.biggerOn) size = currentSize + this.biggerOn + 'px';
         else if (this.smallerOn) size = currentSize - this.smallerOn + 'px';
         else size = currentSize + 'px';
         fontSize.fontSize = size;
         return fontSize;
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../../sass/_variables'
#dark p, #dark p > *
   color: $app-dark-font-color

p
   transition: 0.2s ease
</style>