<template>
   <AdviceModal @closeModal="$emit('closeModal')" :disableCross="true">
      <div class="box">
         <AdviceText :bigger="true" class="title">{{ $t('Advice.logoutText') }}</AdviceText>
         <div class="btns">
            <AdviceBtn @click.native="$emit('closeModal')">
               <AdviceText :smaller="true">{{ $t('Advice.btnCancel') }}</AdviceText>
            </AdviceBtn>
            <AdviceBtn @click.native="logout">
               <AdviceText :smaller="true"> {{ $t('Advice.btnLogout') }} </AdviceText>
            </AdviceBtn>
         </div>
      </div>
   </AdviceModal>
</template>

<script>
import AdviceBtn from './AdviceBtn.vue';
import AdviceText from './AdviceText.vue';
import AdviceModal from './AdviceModal.vue';
import mutations from '../../store/mutation-types';
export default {
   components: {
      AdviceModal,
      AdviceText,
      AdviceBtn,
   },

   mounted() {
      this.$store.commit(`profile/${mutations.SET_LOG_OUT_OPEN}`, true);
   },

   methods: {
      logout() {
         localStorage.user_token = '';
         sessionStorage.isAuth = false;
         sessionStorage.isAuthUser = false;
         if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
               type: 'logout',
            });
         }
         sessionStorage.clear();
         this.$emit('closeModal');
         this.$router.push({ name: 'app-login' });
      },
   },

   destroyed() {
      this.$store.commit(`profile/${mutations.SET_LOG_OUT_OPEN}`, false);
   },
};
</script>

<style lang="sass" scoped>
.box
   max-width: 380px
   width: 100%
   margin: 0 auto

.title
   text-align: center

.btns
   margin-top: 40px
   display: flex
   justify-content: space-between
</style>